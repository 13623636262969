import { render, staticRenderFns } from "./SSOUserVerify.vue?vue&type=template&id=d13ab488&scoped=true"
import script from "./SSOUserVerify.vue?vue&type=script&lang=js"
export * from "./SSOUserVerify.vue?vue&type=script&lang=js"
import style0 from "./SSOUserVerify.vue?vue&type=style&index=0&id=d13ab488&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d13ab488",
  null
  
)

export default component.exports